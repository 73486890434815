import React, { useEffect } from 'react';
import Button from '../../components/Button';
import { Column, Container, Row } from '../../components/Grid';
import Icon from '../../components/Icon';
import Image from '../../components/Image';
import Layout from '../../components/Layout';
import Section from '../../components/Section';

const SelfPacedModule = () => {
  useEffect(() => {
    const iframe = document.getElementById('contentFrame');

    const resizeIframe = () => {
      const iframeDocument =
        iframe.contentDocument || iframe.contentWindow.document;
      iframe.style.height = iframeDocument.body.scrollHeight + 'px';
    };

    const onIframeLoad = () => {
      resizeIframe();

      const iframeDocument =
        iframe.contentDocument || iframe.contentWindow.document;
      const observer = new MutationObserver(resizeIframe);
      observer.observe(iframeDocument.body, {
        childList: true,
        subtree: true,
        attributes: true,
      });

      return () => {
        observer.disconnect();
      };
    };

    iframe.addEventListener('load', onIframeLoad);

    return () => {
      iframe.removeEventListener('load', onIframeLoad);
    };
  }, []);
  return (
    <Layout title="Self-Paced Module">
      <Section>
        <Container fullWidth>
          <Row>
            <h2>Preparing for a Professional Internship</h2>
            <p>
              Designed to deepen understanding of in-class lessons, students
              take the wheel in self-paced modules, completing these activities
              at their own pace. Self-Paced Modules parallel classroom learning
              to build individual mastery of topics for students in the
              classroom, at home, or wherever. 
            </p>
          </Row>
        </Container>
      </Section>
      {/* <Section>
        <Container>
          <Row>
            <Column size={3}>
              <Resource
                img="edresources-activities-biomimicry-2x.jpg"
                title="Biomimicry"
                titleClassOverride="resource__title__purple resource__title__uppercase"
                audience="Grade 6-8"
                duration="40-60 minutes"
                description="Students discover how engineers today are drawing on inspiration from the natural world. In this activity, natural cooling processes show how we may one day keep cool using the same strategies as members of the animal kingdom."
                actions={[
                  {
                    label: 'Classroom Activity',
                    download:
                      '/pdfs/SustainableFutures-TeacherLedActivity-Biomimicry-V2.0.pdf',
                  },
                ]}
              />
            </Column>
            <Column size={3}>
              <Resource
                img="edresources-activities-coolenergy-2x.jpg"
                title="Cool Energy"
                titleClassOverride="resource__title__purple resource__title__uppercase"
                audience="Grade 6-8"
                duration="40-60 minutes"
                description="Students are tasked with comparing renewable and nonrenewable energy sources for the purpose of keeping themselves cool. Students will engage in a WebQuest to compare the various energy sources. "
                actions={[
                  {
                    label: 'Classroom Activity',
                    download:
                      '/pdfs/SustainableFutures-TeacherLedActivity-CoolEnergy-V2.0.pdf',
                  },
                ]}
              />
            </Column>
            <Column size={3}>
              <Resource
                img="edresources-activities-coolcareers-2x.jpg"
                title="Cool Careers"
                titleClassOverride="resource__title__purple resource__title__uppercase"
                audience="Grade 6-8"
                duration="40-60 minutes"
                description="Different careers in cooling (and heating) are presented in a Gallery Walk for students to decide which they think is most interesting and which is most critical to keeping us cool."
                actions={[
                  {
                    label: 'Classroom Activity',
                    download:
                      '/pdfs/SustainableFutures-TeacherLedActivity-CoolCareers-V2.0.pdf',
                  },
                ]}
              />
            </Column>
            <Column size={3}>
              <Resource
                img="edresources-activities-keepitcool-2x.jpg"
                title="Keep It Cool"
                titleClassOverride="resource__title__purple resource__title__uppercase"
                audience="Grade 6-8"
                duration="40-60 minutes"
                description="Students will use their problem-solving skills to design a better lunchbox after learning the difference between thermal insulators and conductors. They will then take what they have learned and extrapolate it into larger cooling applications."
                actions={[
                  {
                    label: 'Classroom Activity',
                    download:
                      '/pdfs/SustainableFutures-TeacherLedActivity-KeepItCool-V2.0.pdf',
                  },
                ]}
              />
            </Column>
          </Row>
        </Container>
      </Section> */}
      <Section>
        <Container>
          <iframe
            id="contentFrame"
            width="100%"
            src="/preparing-for-internship"
            scrolling="no"
            style={{ overflow: 'hidden', border: 'none' }}
          ></iframe>
        </Container>
      </Section>
      <Section>
        <Container>
          <Row>
            <Column size={8}>
              <h3>ABOUT</h3>
              <p>
                Students engage in a self-paced exploration of the career
                benefits of—and preparation needed for—beginning a professional
                internship. 21st century skills such as the “4Cs” are introduced
                and examined with a focus on students building a successful
                future career. 21st century skills such as the “4Cs” are
                introduced and examined with a focus on preparing students for a
                diverse range of traditional and nontraditional career paths.
              </p>
              <br />
              <h3>SUPPORTING RESOURCES</h3>
              <p>
                This self-paced module Digital Lesson Bundle (DLB) has
                everything teachers and their students need to explore and
                prepare for a professional internship, inclusive of multiple
                career paths. Students work through the lesson alone at their
                own pace and then bring together their learnings in groups,
                while the accompanying teacher guide ensures a focused,
                enriching experience.
              </p>
              <Button to="/educator-resources/digital-lesson-bundles">
                Digital Lesson Bundle
                <Icon name="arrowright" marginLeft />
              </Button>
            </Column>
            <Column size={4}>
              <div>
                <Image filename="Trane-SPM-webpage-icon.svg" />
                <div className="virtual-field-trip__card-copy">
                  <p
                    style={{ fontSize: '18px', lineHeight: '26px' }}
                    className="mb-0"
                  >
                    EDUCATOR GUIDE
                  </p>
                  <h3 className="mb-0 mt-0">
                    Preparing Students for a Professional Internship
                  </h3>
                  {/* <div
                    className="pb-0.5"
                    style={{
                      color: 'rgb(102, 102, 102)',
                      fontSize: '0.875rem',
                    }}
                  >
                    {' '}
                    <Icon name="clock" />
                    <span>6 minutes</span>
                  </div> */}
                  <p>
                    Guide students toward an understanding of the importance of
                    professional internships in building a career track. Build
                    students’ skills in the 4Cs: Critical thinking,
                    Communication, Collaboration, and Creativity as they prepare
                    for their professional futures, whether in an office, work
                    site, or a non-traditional career path.
                  </p>
                  <Button
                    target="_blank"
                    to="/pdfs/SustainableFutures-SPM-EdGuide.pdf"
                  >
                    Educator Guide
                    <Icon name="download" marginLeft />
                  </Button>
                </div>
              </div>
            </Column>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export default SelfPacedModule;
